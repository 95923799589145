import { addSameListenerToMulti, appendClass, removeClass } from "./utils";
import { html } from "./tpl";

(function () {
  let init = () => {
    addSameListenerToMulti(
      document.getElementsByClassName("toggle-button"),
      "click",
      function () {
        let classes = this.className.split(" ");
        let toggleTarget = document.getElementById(
          this.getAttribute("data-target")
        );
        if (classes.indexOf("toggle") != -1) {
          removeClass(this, "toggle");
          removeClass(toggleTarget, "toggle");
        } else {
          appendClass(this, "toggle");
          appendClass(toggleTarget, "toggle");
        }
      }
    );

    addSameListenerToMulti(
      document.getElementsByClassName("scroll-link"),
      "click",
      function (e) {
        const scrollTo = e.currentTarget.getAttribute("href").substring(1);
        document
          .getElementById(scrollTo)
          .scrollIntoView({ behavior: "smooth" });
        e.preventDefault();
      }
    );

    var pastWork = {
      mobile: [
        {
          image: require("../images/coreline.gif"),
          title: "Reporting",
          link: "",
        },
        {
          image: require("../images/scoreiton.jpg"),
          title: "Score Tracker",
          link: "",
        },
        {
          image: require("../images/lbb.png"),
          title: "Mastech LBB",
          link:
            "http://www.macquarie.com.au/mgl/au/advisers/services-support/technical-services/mastech-little-black-book/",
        },
        {
          image: require("../images/jetstar.jpeg"),
          title: "Jetstar",
          link: "http://www.jetstar.com/au/en/home",
        },
      ],
      desktop: [
        {
          image: require("../images/itc.jpg"),
          title: "Insurance Tax",
          link:
            "http://www.macquarie.com.au/mgl/au/advisers/products-platforms/tools/calculators/insurance-tax-calculator",
        },
        {
          image: require("../images/agedcare.png"),
          title: "Aged Care",
          link:
            "http://www.macquarie.com.au/mgl/au/advisers/products-platforms/tools/calculators/aged-care-calculator",
        },
        {
          image: require("../images/rhlventure.png"),
          title: "RHL Venture",
          link: "http://rhl.ventures",
        },
        {
          image: require("../images/pascalhow.png"),
          title: "Pascal How",
          link: "http://pascalhow.com",
        },
        {
          image: require("../images/efficiency.png"),
          title: "Effiency Tool",
          link:
            "http://www.macquarie.com.au/mgl/au/advisers/products-platforms/tools/calculators/efficiency-calculator",
        },
        {
          image: require("../images/aa.png"),
          title: "American Way",
          link: "https://americanwaymagazine.com/",
        },
      ],
      robotics: [
        {
          image: require("../images/followme.png"),
          title: "Deep Learning",
          link: "https://github.com/lisaljl/Udacity-RoboND-FollowMe-Project",
        },
        {
          image: require("../images/perception.png"),
          title: "Object Recognition",
          link: "https://github.com/lisaljl/Udacity-RoboND-Perception-Project",
        },
        {
          image: require("../images/kinematics.png"),
          title: "Robotics Kinematics",
          link: "https://github.com/lisaljl/Udacity-RoboND-Kinematics",
        },
        {
          image: require("../images/arm.png"),
          title: "Haptics for MIS",
          link: "/documents/MIS.pdf",
        },
        {
          image: require("../images/scan.png"),
          title: "3D Imaging and Sensing",
          link: "/documents/imaging.pdf",
        },
      ],
    };

    function tpl(val) {
      if (val.link) {
        return `<article>
				<div class="artbox">
						<figure class="box-main" style="background-image: url(${val.image})" alt="${val.title}"></figure>
						<div class="box-title">
								<a target="_blank" href=${val.link}>
										${val.title}
												<br>
												<span>
														View
												</span>
								</a>
						</div>
				</div>
		</article>`;
      }

      return `<article>
                <div class="artbox">
                    <figure class="box-main" style="background-image: url(${val.image})" alt="${val.title}"></figure>
                    <div class="box-title">
										<label>${val.title}</label>
                    </div>
                </div>
            </article>`;
    }

    let pastWorkMobTpl = "";
    pastWork["mobile"].forEach((val) => {
      pastWorkMobTpl += html(tpl(val));
    });
    document.getElementById("pastWork-mobile").innerHTML = pastWorkMobTpl;

    let pastWorkDesktopTpl = "";
    pastWork["desktop"].forEach((val) => {
      pastWorkDesktopTpl += html(tpl(val));
    });
    document.getElementById("pastWork-web").innerHTML = pastWorkDesktopTpl;

    let pastWorkRoboticsTpl = "";
    pastWork["robotics"].forEach((val) => {
      pastWorkRoboticsTpl += html(tpl(val));
    });
    document.getElementById(
      "pastWork-robotics"
    ).innerHTML = pastWorkRoboticsTpl;

    var tags = ["Passionate", "Creative", "Collaborative", "Learning"];
    const tagsMap = tags.map((tag) => `<label>${tag}</label>`);
    document.getElementById("aboutMeTags").innerHTML = tagsMap.join("");
  };

  init();
})();
